export const ProcessStateToString = (state: ProcessState) => {
	if (state === ProcessState.InProgress) return "In progress";
	if (state === ProcessState.Signed) return "Signed";
	if (state === ProcessState.Completed) return "Completed";
	return "Abandoned";
};

export const QuizSessionStatusToString = (state: QuizSessionStatus) => {
	if (state === QuizSessionStatus.Passed) return "Passed";
	if (state === QuizSessionStatus.Failed) return "Failed";
};

export enum ProcessState {
	InProgress = 1,
	Completed = 2,
	Abandoned = 3,
	Signed = 4,
}

export enum QuizSessionStatus {
	Passed = 1,
	Failed = 2,
}

export enum ProcessType {
	Step = 1,
	Checklist = 2,
}

export const ProcessTypeToString = (state: ProcessType) => {
	if (state === ProcessType.Step) return "Process";
	return "Checklist";
};

export interface AuditProcess {
	id: string;
	patientCode: string;
	completedOn: string;
	ownerId: string;
	owner: string;
	startDate: string;
	patientCodeDate: string;
	state: ProcessState;
	practiceId: string;
	processTemplateId: string;
	processTemplateGroupId: string;
	templateName: string;
	duration: number;
	abandonReason: string;
	numberOfTemplateSteps: number;
	currentStepIndex: number;
	note: string;
	isChecklist: boolean;
	quizSession: QuizSession;
	isRequiredImagesInSteps: boolean;
}
export interface QuizSession {
	sessionStatus: QuizSessionStatus;
	correctQuestions: number;
	totalQuestions: number;
}
