import { ILoginCredentials } from "shared/models/ILoginCredentials";
import { unwrapResult } from "@reduxjs/toolkit";
import { logout, setTokens, localAuthLogin, ILoginResponse } from "./authSlice";
import store, { AppDispatch } from "index";
import { IUserCredentials, LocalTokenFields } from "shared/models/IUserCredentials";
import axios, { AxiosResponse } from "axios";
import { history } from "index";
import jwt_decode from "jwt-decode";
import { reset as resetAiState } from "features/panels/Admin/AIModule/services/aiSlice";

export const handleLogin = (formValues: ILoginCredentials) => {
	const dispatch: AppDispatch = store.dispatch;

	const promise = new Promise<ILoginResponse>((resolve, reject) => {
		dispatch(localAuthLogin(formValues))
			.then(unwrapResult)
			.then((data: ILoginResponse) => resolve(data))
			.catch((errors) => reject(errors));
	});

	return promise;
};

export const handleLogout = () => {
	const dispatch = store.dispatch;
	void dispatch(resetAiState());
	void dispatch(logout());
	if (refreshTimer) clearTimeout(refreshTimer);
	history.push("/");
};

export const handleRefreshTimerOnRefreshPage = (user?: IUserCredentials) => {
	if (user?.isLoggedIn && user.authType === "local") {
		setRefreshTimer(user.accessToken);
	}
};

let refreshTimer: NodeJS.Timeout;
const setRefreshTimer = (accessToken: string) => {
	const dispatch: AppDispatch = store.dispatch;
	const refreshRemainingTime = getRefreshRemainingTime(accessToken);
	clearTimeout(refreshTimer);
	refreshTimer = setTimeout(() => {
		const state = store.getState();
		if (state.auth.authType !== "local") return;
		const { accessToken, refreshToken } = state.auth;
		void axios
			.post(`api/Authenticate/refresh-token`, {
				refreshToken,
				accessToken,
			})
			.then((res: AxiosResponse<LocalTokenFields>) => {
				if (res.status === 200) {
					dispatch(setTokens({ ...res.data, type: "local" }));
					setRefreshTimer(res.data.accessToken);
				} else {
					handleLogout();
				}
			});
	}, refreshRemainingTime);
};

export const getAuthConfig = async (baseUrl: string) => {
	const response = await fetch(`${baseUrl}/api/authenticate/getAuthConfig`, {
		mode: "cors",
		method: "GET",
	});
	return await response.text();
};

const getRefreshRemainingTime = (token: string) => {
	const decode = jwt_decode<JWT>(token);
	const dateNow = new Date();
	const tokenExpiryDate = new Date(decode.exp * 1000);

	let remainingTime: number = tokenExpiryDate.getTime() - dateNow.getTime();
	remainingTime = remainingTime / 2;
	return remainingTime;
};

interface JWT {
	exp: number;
}
