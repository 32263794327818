import { EntityPriority } from "shared/enums/EntityPriority";
import { EntityState } from "../enums/EntityState";
import { Option } from "../../features/panels/Admin/shared/ProcessTemplate/ConditionalLinksModal";
import { Guid, IdName } from "./HelperModels";
import { SimpleUser } from "shared/models/SimpleUser";
import { IProcessMap } from "./ProcessMap";
import { Quiz } from "./Quiz";
import { StepRequireImageStatus } from "shared/enums/StepRequireImageStatus";
import { ProcessStepRequireImage } from "./Step";

export enum AttachmentState {
	unmodified = 1,
	new = 2,
	removed = 3,
	edited = 4,
}

export enum TemplateVariant {
	V1 = 0,
	V2 = 1,
	V3 = 2,
}

export type Attachment = {
	id?: string;
	url: string;
	name: string;
	state: AttachmentState;
	file?: any;
	fileName: string;
	stepIndex?: number;
	path: string;
	attachmentURL?: string;
};

export type Step = {
	id: string;
	title: string;
	description: string;
	index: number;
	isSkippable: boolean;
	isEndStep: boolean;
	isCompleted?: boolean;
	completed?: boolean;
	attachments: Attachment[];
	isRequired?: boolean;
	isConditional: boolean;
	conditionText?: string;
	state?: "published";
	linkedSteps: number[];
	linkedOptions: Option[];
	processTemplateStepId?: string;
	processTemplateId: string;
	hasAccessToLinkedTemplate?: boolean;
	linkedProcessTemplateId?: string;
	linkedProcessTemplateTitle?: string;
	linkedProcessTemplateVersionGroupId?: string;
	role: IdName | null;
	linkedProcessTemplateVersionGroupName?: string;
	doesCopiedLinkedTemplateExistInOrganisation?: boolean;
	requireImage: StepRequireImageStatus;
	requireImageAttachments?: ProcessStepRequireImage[];
};

export type ProcessTemplateBase = {
	id: string;
	name: string;
	description: string;
	state: EntityState;
	steps: Step[];
	organizationId: string;
	versionNumber: number;
	versionGroupId: string;
	attachments: Attachment[];
	variant: TemplateVariant;
	priority: EntityPriority;
	sla: number;
	map: IProcessMap | null;
	isIdentifierRequired?: boolean;
	isChecklist: boolean;
	isSignatureRequired: boolean;
	categoryId: string;
};

export type ProcessTemplate = ProcessTemplateBase & {
	reviewPeriodInDays: number;
	notificationPeriodInDays: number;
	trainingPeriodInDays: number;
	state: EntityState;
	steps: Step[];
	quizzes: Quiz[];
	reviewedBy?: Pick<SimpleUser, "firstName" | "lastName">;
	reviewedById?: string;
	reviewedOn?: Date;
	expectedTimeToCompleteInMinutes: number;
	organizationName: string;
	roles: IdName[];
	expert?: Pick<SimpleUser, "email" | "id"> & { name: string };
	expertId?: Guid;
	tags: IdName[];
	policies: IdName[];
	isGlobal: boolean;
	startedCopiedProcesses: number;
	copiedFromGlobalTemplateId?: Guid;
	approvers: IdName[];
	teams: IdName[];
	approversHistory: ApprovalHistory[];
	allowPageDownload: boolean;
	modifiedOn: Date | null | string;
	modifiedBy: string | null;
	hasQuiz: boolean;
	canEdit: boolean;
	editedBy: string;
	canReview: boolean;
};

export type ApprovalHistory = {
	id: string;
	description: string;
	createdOn: Date;
	versionNumber: number;
};

export type SendToApproval = {
	sendEmailAfterPublish: boolean;
	updateApproversNotes: string;
	updateNotes: string;
};

/// USED IN CONTINUE PROCESS PAGE ON TM
export type ProcessTemplateContinueModel = ProcessTemplateBase & {
	roles: IdName[];
	expert?: Pick<SimpleUser, "email" | "id"> & { name: string };
	hasQuiz: boolean;
};

/// USED IN DOWNLOAD PAGE TL & TM
export type ProcessTemplateDownloadModel = ProcessTemplateBase & {
	roles: IdName[];
	expert?: Pick<SimpleUser, "email" | "id"> & { name: string };
	reviewedBy?: Pick<SimpleUser, "email" | "id"> & { name: string };
	reviewedById?: string;
	teams: IdName[];
	tags: IdName[];
	policies: IdName[];
	reviewedOn?: Date;
	reviewPeriodInDays: number;
	expectedTimeToCompleteInMinutes?: number;
	organizationName: string;
};

export type ProcessTemplateDuplicateModel = Pick<
	ProcessTemplateBase,
	"id" | "name" | "isChecklist" | "state" | "versionGroupId" | "versionNumber" | "organizationId"
> & { organizationName: string };

export type ProcessTemplatesCount = Record<"total" | "published" | "draft" | "overdueReview", number>;
