import React, { ReactElement, useState } from "react";
import { DialogContentText } from "@mui/material";
import CustomPopup from "shared/components/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "app/themes";
import CoreButton from "shared/components/Buttons/CoreButton";
import InfoIcon from "@mui/icons-material/Info";
import TooltipButton from "shared/components/Buttons/TooltipButton";

interface Props {
	title: string;
	description: string;
	opener?: ReactElement;
	tooltipText?: string;
}

export default function DescriptionDialog(props: Props): ReactElement {
	const classes = useStyles(props);
	const [open, setOpen] = useState(false);

	return (
		<CustomPopup
			title={
				<div className={classes.popupTitle}>
					<div className={classes.titleRoot}>
						<span>{props.title}</span>
					</div>
					<span onClick={() => setOpen(false)}>
						<CloseIcon />
					</span>
				</div>
			}
			renderContent={() => (
				<DialogContentText style={{ minHeight: "6rem" }}>{props.description}</DialogContentText>
			)}
			renderPopupOpener={() => (
				<>
					{props.opener ?? (
						<>
							{!!props.tooltipText ? (
								<TooltipButton
									variant="primary"
									color="secondary"
									className={classes.description}
									tooltipText={props.tooltipText}
								>
									<InfoIcon style={{ fill: theme.palette.common.white }} />
								</TooltipButton>
							) : (
								<CoreButton
									variant="primary"
									color="secondary"
									className={classes.description}
								>
									<InfoIcon style={{ fill: theme.palette.common.white }} />
								</CoreButton>
							)}
						</>
					)}
				</>
			)}
			renderCustomActionButtons={() => {}}
			open={open}
			setOpen={setOpen}
		/>
	);
}

const useStyles = makeStyles({
	description: {
		padding: "8px",
		borderRadius: theme.shape.borderRadiusPill,
		transition: "background-color .3s",
		"&:hover": {
			backgroundColor: theme.palette.secondary.dark,
		},
	},
	paperRoot: {
		padding: 24,
		borderRadius: 12,
	},
	reasonContainer: {
		display: "inline-block",
		background: "#f5f5f5",
		borderRadius: 30,
		padding: "14px 24px",
		marginRight: 14,
		marginBottom: 14,
		cursor: "pointer",
		fontWeight: "bold",
		color: "rgba(0, 0, 0, 0.56)",
		border: "1px solid #f5f5f5",
	},
	selected: {
		background: theme.palette.primary.main,
		color: "#fff",
		border: `1px solid ${theme.palette.primary.main}`,
	},
	popupTitle: {
		display: "flex",
		justifyContent: "space-between",
		"& > span:last-of-type": {
			cursor: "pointer",
		},
	},
	titleRoot: {
		display: "flex",
		alignItems: "center",
		"& > span": {
			display: "flex",
		},
		"& > span:first-of-type": {
			marginRight: 8,
		},
	},
	actionRoot: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		marginBottom: 12,
		"& > div": {
			padding: "0px 50px",
		},
	},
});
