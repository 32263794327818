// utils & services
import { createSlice } from "@reduxjs/toolkit";
// types
import { SOP, ProcessType, SopIdObject } from "../utils/types"

export type AIState = {
    isGenerating: boolean;
    isMerging: boolean;
    responseGenerated: boolean;
    mergingOpen: boolean;
    sopData: SOP[];
    sopDataHistory: SOP[][];
    integratedSops: SOP[];
    invalidSopText: string;
    docTitle: string;
    inputString: string;
    processType: ProcessType;
    pollingUri: string;
    sopIds: SopIdObject[];
    currentSopIndex: number;
}

const initialState: AIState = {
    isGenerating: false,
    isMerging: false,
    responseGenerated: false,
    mergingOpen: false,
    sopData: [] as SOP[],
    sopDataHistory: [] as SOP[][],
    integratedSops: [] as SOP[],
    invalidSopText: "",
    docTitle: "",
    inputString: "",
    processType: "sop",
    pollingUri: "",
    sopIds: [] as SopIdObject[],
    currentSopIndex: 0,
}

export const aiSlice = createSlice({
    name: "ai",
    initialState: initialState,
    reducers: {
        setIsGenerating: (state, action) => {
            state.isGenerating = action.payload;
        },
        setIsMerging: (state, action) => {
            state.isMerging = action.payload;
        },
        setResponseGenerated: (state, action) => {
            state.responseGenerated = action.payload;
        },
        setMergingOpen: (state, action) => {
            state.mergingOpen = action.payload;
        },
        setSopData: (state, action) => {
            state.sopData = action.payload;
        },
        setSopDataHistory: (state, action) => {
            state.sopDataHistory = action.payload;
        },
        setInvalidSopText: (state, action) => {
            state.invalidSopText = action.payload;
        },
        setDocTitle: (state, action) => {
            state.docTitle = action.payload;
        },
        setInputString: (state, action) => {
            state.inputString = action.payload;
        },
        setProcessType: (state, action) => {
            state.processType = action.payload;
        },
        setPollingUri: (state, action) => {
            state.pollingUri = action.payload;
        },
        setSopIds: (state, action) => {
            state.sopIds = action.payload;
        },
        setIntegratedSops: (state, action) => {
            state.integratedSops = action.payload;
        },
        setCurrentSopIndex: (state, action) => {
            state.currentSopIndex = action.payload;
        },
        reset: () => initialState,
    },
});

export const {
    setIsGenerating,
    setIsMerging,
    setResponseGenerated,
    setMergingOpen,
    setSopData,
    setSopDataHistory,
    setInvalidSopText,
    setDocTitle,
    setInputString,
    setProcessType,
    setPollingUri,
    setSopIds,
    setIntegratedSops,
    setCurrentSopIndex,
    reset,
} = aiSlice.actions;